import { ApiEnvironment, FirebaseEnvironment } from '@clients/api';
import { version } from './version';
import { SentryEnvironment, VersionEnvironment } from '@clients/helpers';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: VersionEnvironment &
  ApiEnvironment &
  FirebaseEnvironment &
  SentryEnvironment & { production: boolean; enableServiceWorker: boolean } = {
  production: false,
  enableServiceWorker: true,
  version,
  api: 'https://api.bc.gknpm.fortysix.world/business-connect-api',
  netApi: 'https://api.bc.gknpm.fortysix.world/apinet',
  firebase: {
    apiKey: 'AIzaSyC0re4436Mi1E9LEu0KsMUJzDzCouGYyJs',
    authDomain: 'business-connect-dev.firebaseapp.com',
    databaseURL: 'https://business-connect-dev.firebaseio.com',
    projectId: 'business-connect-dev',
    storageBucket: '',
    messagingSenderId: '808008049241',
    appId: '1:808008049241:web:70685e2be8e91d5ba9c748',
  },
  sentry: {
    dns: 'https://427ef036d0be4050874eb88738bd4479@sentry.fortysix.world/11',
    environment: 'dev',
    enabled: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
